import React, { PureComponent } from 'react'
import { SearchBox } from 'drivehub-rc'
import LazyLoad from 'react-lazyload'
import './style.scss'

class SearchSection extends PureComponent {
  state = {
    isLoadingSearchBox: true,
  }
  componentDidMount() {
    this.setState({
      isLoadingSearchBox: false,
    })
  }
  render() {
    const {
      title,
      subTitle,
      locationID,
      brandId,
      shortValueProps,
      prefix,
    } = this.props
    const { isLoadingSearchBox } = this.state
    return (
      <div className="section__search">
        <div className="container container--search">
          <div className="search-header">
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
            <p
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          </div>
          <div className="search-content">
            {isLoadingSearchBox ? (
              <div style={{ height: '240px' }} />
            ) : (
              <SearchBox locationID={parseInt(locationID)} vendorID={brandId} />
            )}
          </div>
        </div>
        {/* Mobile Footer Start */}
        <LazyLoad>
          <div className="container container--contact">
            <p className="contact-header">สอบถามเพิ่มเติม</p>
            <div className="contact-content">
              <div className="contact-item">
                <a
                  id="contact-line"
                  className="section__wrapper"
                  rel="nofollow"
                  href="https://line.me/ti/p/%40drivehub"
                >
                  <img src={'./assets/line.svg'} alt="@drivehub" />
                  <span>@drivehub</span>
                </a>
              </div>
              <div className="contact-item separator">
                <a
                  id="contact-phone"
                  className="section__wrapper"
                  rel="nofollow"
                  href="tel:+6620385222"
                >
                  <img src={'./assets/phone.svg'} alt="02 038 5222" />
                  <span>02 038 5222</span>
                </a>
              </div>
            </div>
          </div>
        </LazyLoad>
        {/* Mobile Footer End */}
        {/* Desktop Footer Start */}
        <LazyLoad>
          <div className="container--value-props">
            {shortValueProps.map((el, i) => (
              <div className="value-prop-item" key={i}>
                <i className="flaticon-checked-circle" />
                <span>{el[`content_${prefix}`]}</span>
              </div>
            ))}
          </div>
        </LazyLoad>
        {/* Desktop Footer End */}
      </div>
    )
  }
}

export default SearchSection
