import './style.scss'
import React, { PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'
import { withTranslation } from '../../../components/locales'

class ServiceAlliance extends PureComponent {
  render() {
    const { t, lang, interBrands } = this.props

    return (
      <LazyLoad>
        <div className="service-alliance">
          <h2>{t('services_alliance.header')}</h2>
          <div className="items">{alliance(t, lang, interBrands)}</div>
        </div>
      </LazyLoad>
    )
  }
}

const alliance = (t, lang, interBrands) => {
  return interBrands.map(function(object, i) {
    const data = object['node']['frontmatter']
    return brand(t, lang, data, i)
  })
}

const brand = (t, lang, data, i) => {
  return (
    <a className="item" href={`/${lang}/${data['path']}`} key={'item-' + i}>
      <img
        src={data['brand_image_for_homepage']}
        alt={data['brand_image_for_homepage_alt']}
        key={'img-' + i}
      />
      <p key={'name-' + i}>
        {' '}
        {t('services_alliance.rent')} {data[`name_${lang}`]}{' '}
      </p>
    </a>
  )
}

const serviceAlliance = props => (
  <StaticQuery
    query={graphql`
      query listInterBrand {
        allMarkdownRemark(
          filter: {
            fields: { collection: { eq: "interbrand" } }
            frontmatter: { activate: { eq: true } }
          }
        ) {
          edges {
            node {
              frontmatter {
                path
                brand_image_for_homepage
                brand_image_for_homepage_alt
                name_en
                name_th
                activate
              }
            }
          }
        }
      }
    `}
    render={data => {
      const interBrands = data.allMarkdownRemark.edges
      return <ServiceAlliance {...props} interBrands={interBrands} />
    }}
  />
)

export default withTranslation('homepage')(serviceAlliance)
