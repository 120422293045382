import React, { PureComponent } from 'react'
import { withTranslation } from '../../../components/locales'
import { setupDateTime } from '../../../utils/index'
import { WEB_SEARCH_LINK } from '../../../../config'
import LazyLoad from 'react-lazyload'
import { Link } from 'gatsby'
import { Badge } from 'drivehub-rc'
import './style.scss'

const path = service => {
  const bookingBegin = setupDateTime(true).format('YYYY-MM-DD HH:mm')
  const bookingEnd = setupDateTime(false).format('YYYY-MM-DD HH:mm')

  const bookingBeginInter = setupDateTime(true, 'inter').format(
    'YYYY-MM-DD HH:mm',
  )
  const bookingEndInter = setupDateTime(false, 'inter').format(
    'YYYY-MM-DD HH:mm',
  )

  switch (service) {
    case 'rent_short':
      return (
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBegin +
        '&booking_end=' +
        bookingEnd
      )
    case 'rent_inter':
      return (
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBeginInter +
        '&booking_end=' +
        bookingEndInter +
        '&sources=inter'
      )
    case 'rent_local':
      return (
        WEB_SEARCH_LINK +
        '?&open_search=true&location_id=1&booking_begin=' +
        bookingBegin +
        '&booking_end=' +
        bookingEnd +
        '&sources=local'
      )
    default:
      return '#'
  }
}

class ServiceDrivehub extends PureComponent {
  render() {
    const { t } = this.props

    return (
      <LazyLoad>
        <div className="service-drivehub">
          <h2>{t('services_drivehub.header')}</h2>
          <div className="cards">
            <a
              className="card card--rent_short alpha"
              href={path('rent_short')}
            >
              <p>{t('services_drivehub.short_term')}</p>
            </a>
            <Link
              to="/th/long-term-rental"
              className="card card--rent_long alpha"
            >
              <p>{t('services_drivehub.long_term')}</p>
            </Link>
            <Link
              to="/th/car-with-driver"
              className="card card--rent_cwd alpha"
            >
              <p>{t('services_drivehub.car_with_driver')}</p>
            </Link>
            <a
              className="card card--rent_local alpha"
              href={path('rent_local')}
            >
              <p>{t('services_drivehub.local')}</p>
              <Badge
                type="local"
                size="small"
                style={{ position: 'absolute', left: '8px', top: '8px' }}
              />
            </a>
            <a
              className="card card--rent_inter alpha"
              href={path('rent_inter')}
            >
              <p>{t('services_drivehub.inter')}</p>
              <Badge
                type="inter"
                size="small"
                style={{ position: 'absolute', left: '8px', top: '8px' }}
              />
            </a>
            <div className="card card--extra"></div>
          </div>
        </div>
      </LazyLoad>
    )
  }
}

export default withTranslation('homepage')(ServiceDrivehub)
