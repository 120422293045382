import React, { PureComponent } from 'react'
import PromotionList from '../../../components/promotion-list'
import './style.scss'

class SectionPromotions extends PureComponent {
  render() {
    const { isMobile } = this.props

    return (
      <div className="section__promotions">
        <div className="container container--promotions">
          <PromotionList isHomePage isMobile={isMobile} />
        </div>
      </div>
    )
  }
}

export default SectionPromotions
