import React, { Component } from 'react'
import Page from '../index'

export default class TH extends Component {
  componentDidMount() {
    if (window.localStorage) {
      window.localStorage.setItem('lang', 'th')
    }
  }
  render() {
    return <Page prefix="th" />
  }
}
