import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import IntroduceModal from '../components/introduce-modal'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Background from '../components/background'
import PromotionModal from '../components/promotion-modal'

import SearchSection from '../pages-components/homepage/section-search'
import SectionPromotions from '../pages-components/homepage/section-promotions'
import SectionCarAvailable from '../pages-components/homepage/section-car-available'
import SectionValueProps from '../pages-components/homepage/section-value-props'
import SectionServices from '../pages-components/homepage/section-services'
import SectionRegions from '../pages-components/homepage/section-regions'
import SectionBookingRetrieve from '../pages-components/homepage/section-booking-retrieve'
import { Context, Theme } from 'drivehub-rc'
import './style.scss'

const IndexPage = props => (
  <StaticQuery
    query={graphql`
      query {
        boy01: file(relativePath: { eq: "assets/boy-01.png" }) {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        homepage: allMarkdownRemark(
          filter: { fields: { collection: { eq: "homepage" } } }
        ) {
          edges {
            node {
              frontmatter {
                hero
                header
                title
                sub_title
                description
                keywords
                default_city
              }
            }
          }
        }
        site_description: allMarkdownRemark(
          filter: { fields: { collection: { eq: "site_description" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                short_value_props {
                  content_th
                  content_en
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Index {...props} data={data} />}
  />
)

class Index extends Component {
  static defaultProps = {
    prefix:
      typeof window === 'object'
        ? window.localStorage.getItem('lang') || 'th'
        : 'th',
  }
  constructor(props) {
    super(props)

    this.state = {
      isMobile: true,
      isHomepage: true,
      isHidePromotionList: false,
    }
  }

  componentDidMount() {
    const initialWidth = window.innerWidth

    this.setState({ isMobile: initialWidth <= 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
  }

  render() {
    const data = this.props.data
    const prefix = this.props.prefix
    const { isMobile, isHomepage } = this.state

    const { homepage, site_description } = data
    const { short_value_props } = site_description.edges[0].node.frontmatter
    const {
      default_city,
      header,
      title,
      sub_title,
      description,
      keywords,
      hero,
    } = homepage.edges[0].node.frontmatter

    return (
      <Theme>
        <Context isMobile={isMobile}>
          <Layout
            prefix={prefix}
            isMobile={isMobile}
            menuStyle={
              isMobile
                ? null
                : { position: 'absolute', width: '100%', zIndex: 10 }
            }
            isHomePage={isHomepage}
          >
            <SEO
              prefix={prefix}
              title={header}
              description={description}
              keywords={keywords}
            />
            <PromotionModal />
            <div className="section">
              <Background src={hero} className="header--bg">
                <SearchSection
                  title={title}
                  subTitle={sub_title}
                  shortValueProps={short_value_props}
                  prefix={prefix}
                  isMobile={isMobile}
                />
              </Background>
              <SectionPromotions isMobile={isMobile} prefix={prefix} />
              <SectionCarAvailable
                isMobile={isMobile}
                prefix={prefix}
                cityID={parseInt(default_city)}
              />
              <SectionValueProps isMobile={isMobile} prefix={prefix} />
              <SectionServices />
              <SectionRegions />
              <SectionBookingRetrieve />
            </div>

            <IntroduceModal prefix={prefix} />
          </Layout>
        </Context>
      </Theme>
    )
  }
}
export default IndexPage
