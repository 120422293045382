import React, { PureComponent } from 'react'
import { withTranslation } from '../../../components/locales'
import LazyLoad from 'react-lazyload'
import './style.scss'

class SectionValueProps extends PureComponent {
  state = {
    isExpanded: false,
  }
  toggle = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }
  render() {
    const { t, prefix, isMobile } = this.props
    const { isExpanded } = this.state
    return (
      <LazyLoad>
        <div className="section__value-props">
          <div
            className={`container container--value-props ${
              isExpanded ? 'expand' : 'close'
            }`}
          >
            <div className="value-props-header">
              <h2>{t('values_props.header')}</h2>
            </div>
            <div className="value-props-content">
              {items.map(item => {
                return (
                  <div
                    className="col-10 col-md-4 col-xl-3 value-props-item"
                    key={item.alt}
                  >
                    <img src={item.icon} alt={item.alt} />
                    <h3>{item.text[prefix]}</h3>
                  </div>
                )
              })}
            </div>
          </div>
          {isMobile && (
            <div className="d-flex justify-content-center">
              <button
                id="whydrivehub-expand-button"
                className={`btn-ghost ${isExpanded ? 'btn-ghost--active' : ''}`}
                onClick={this.toggle}
              >
                <span>
                  {isExpanded
                    ? t('values_props.btn_expand')
                    : t('values_props.btn_close')}
                </span>
                <i
                  className={
                    isExpanded ? 'flaticon-arrow-up' : 'flaticon-arrow-down'
                  }
                />
              </button>
            </div>
          )}
        </div>
      </LazyLoad>
    )
  }
}

const items = [
  {
    alt: 'ค้นหารถเช่า',
    icon: require('./assets/1.svg'),
    text: {
      en: 'ค้นหารถเช่าเพียงไม่กี่คลิก',
      th: 'ค้นหารถเช่าเพียงไม่กี่คลิก',
    },
  },
  {
    alt: 'รับประกันราคารถเช่า',
    icon: require('./assets/2.svg'),
    text: {
      en: 'รับประกันราคาดีที่สุด',
      th: 'รับประกันราคาดีที่สุด',
    },
  },
  {
    alt: 'จองฟรีไม่มีค่าธรรมเนียม',
    icon: require('./assets/3.svg'),
    text: {
      en: 'จองฟรีไม่มีค่าธรรมเนียมใดๆ',
      th: 'จองฟรีไม่มีค่าธรรมเนียมใดๆ',
    },
  },
  {
    alt: 'มีไม่มีบัตรเครดิตก็เช่าได้',
    icon: require('./assets/4.svg'),
    text: {
      en: 'มีหรือไม่มีบัตรเครดิตก็เช่าได้',
      th: 'มีหรือไม่มีบัตรเครดิตก็เช่าได้',
    },
  },
  {
    alt: 'ปลอดภัยหายห่วง',
    icon: require('./assets/5.svg'),
    text: {
      en: 'ปลอดภัยตั้งแต่เริ่มจองจนสิ้นสุดการเช่า',
      th: 'ปลอดภัยตั้งแต่เริ่มจองจนสิ้นสุดการเช่า',
    },
  },
  {
    alt: 'มีเจ้าหน้าที่ดูแล',
    icon: require('./assets/6.svg'),
    text: {
      en: 'มีเจ้าหน้าที่คอยให้บริการตลอดการเช่า',
      th: 'มีเจ้าหน้าที่คอยให้บริการตลอดการเช่า',
    },
  },
  {
    alt: 'มีมาตรฐาน',
    icon: require('./assets/7.svg'),
    text: {
      en: 'คัดเลือกบริษัทรถเช่าที่ได้มาตรฐานเท่านั้น',
      th: 'คัดเลือกบริษัทรถเช่าที่ได้มาตรฐานเท่านั้น',
    },
  },
]

export default withTranslation('homepage')(SectionValueProps)
