import React, { PureComponent } from 'react'
import { withTranslation } from '../../../components/locales'
import LazyLoad from 'react-lazyload'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'

class SectionRegions extends PureComponent {
  render() {
    const { t, lang, regions } = this.props

    return (
      <LazyLoad>
        <div>
          <div className="section__regions">
            <div className="container container--regions">
              <h3>{t('regions.header')}</h3>
              <div className="row">{regionsList(t, lang, regions)}</div>
            </div>
          </div>
        </div>
      </LazyLoad>
    )
  }
}

const regionsList = (t, lang, regions) => {
  return Object.entries(regions)
    .filter(([key, _]) => regions[key].length > 0)
    .map(([key, _]) => (
      <div className="col-6 col-md-2" key={'regions-' + key}>
        <ul key={'regions-name-' + key}>
          <h4>
            <span className="hide">{t('regions.rent')}</span>
            {t(`regions.${key}`)}
          </h4>
        </ul>
        {citiesList(t, lang, regions[key])}
      </div>
    ))
}

const citiesList = (t, lang, cities) => {
  return cities.map(function(city, i) {
    return cityName(t, lang, city, i)
  })
}

const cityName = (t, lang, city, i) => {
  return (
    <li key={'city-' + i}>
      <a href={`/${lang}/${city['path']}`} key={'city-name-' + i}>
        {t('regions.rent') + city[`name_${lang}`]}
      </a>
    </li>
  )
}

const sectionRegions = props => (
  <StaticQuery
    query={graphql`
      query MyQuery {
        allMarkdownRemark(
          filter: {
            fields: { collection: { eq: "city" } }
            frontmatter: { activate: { eq: true }, no_city_page: { ne: true } }
          }
          sort: { fields: [frontmatter___name] }
        ) {
          edges {
            node {
              frontmatter {
                path
                name_th
                name_en
                region
              }
            }
          }
        }
      }
    `}
    render={data => {
      const cities = data.allMarkdownRemark.edges
      const regions = {
        north: [],
        northEast: [],
        central: [],
        east: [],
        west: [],
        south: [],
        other: [],
      }

      cities.forEach(function(object) {
        const city = object.node.frontmatter

        switch (city.region) {
          case 'ภาคเหนือ':
            return regions.north.push(city)
          case 'ภาคอีสาน':
            return regions.northEast.push(city)
          case 'ภาคกลาง':
            return regions.central.push(city)
          case 'ภาคตะวันออก':
            return regions.east.push(city)
          case 'ภาคตะวันตก':
            return regions.west.push(city)
          case 'ภาคใต้':
            return regions.south.push(city)
          default:
            return regions.other.push(city)
        }
      })

      return <SectionRegions {...props} regions={regions} />
    }}
  />
)

export default withTranslation('homepage')(sectionRegions)
