import React, { Component } from 'react'
import Dialog from 'rc-dialog'
import moment from 'moment'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { extension: { regex: "/png|jpg|jpeg/" } }) {
          edges {
            node {
              extension
              relativePath
              childImageSharp {
                fluid(maxWidth: 4000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={({ images }) => {
      const selected = images.edges
        .filter(image => image.node.relativePath.includes('intro'))
        .map(({ node }) => node)
      return <IntroduceModal images={selected} />
    }}
  />
)
class IntroduceModal extends Component {
  state = {
    step: 0,
    visible: false,
  }
  componentDidMount = () => {
    if (window) {
      const lastVisit = window.localStorage.getItem('visited_introduce')
      if (parseInt(lastVisit) > parseInt(moment().unix())) {
        return this.setState({ visible: false })
      }
      window.localStorage.setItem(
        'visited_introduce',
        moment()
          .add(2, 'hours')
          .unix(),
      )
      this.setState({ visible: true })
    }
    if (this.state.visible) document.documentElement.style.overflow = 'hidden'
    else document.documentElement.style.overflow = 'scroll'
  }

  buttonOnClick = () => {
    if (this.state.step < 2) {
      return this.setState({ step: this.state.step + 1 })
    }
    this.setState({ step: 0, visible: false })
    document.documentElement.style.overflow = 'scroll'
  }

  onClose = () => {
    document.documentElement.style.overflow = 'scroll'
    this.setState({ visible: false, step: 0 })
  }

  get buttonText() {
    switch (this.state.step) {
      case 2:
        return 'เริ่มค้นหารถเช่าที่คุ้มค่าที่สุดได้เลย'
      default:
        return 'ดูต่อไป'
    }
  }

  get nextButton() {
    return (
      <button
        className="dh-button dh-animate-pulse"
        onClick={this.buttonOnClick}
      >
        {this.buttonText}
      </button>
    )
  }
  get step() {
    return `${this.state.step + 1}/3`
  }
  get imageFluid() {
    const selected = this.props.images.find(image =>
      image.relativePath.includes(this.state.step + 1),
    )
    return selected.childImageSharp.fluid
  }
  render() {
    return (
      // <div className="introduce--modal">
      <Dialog
        destroyOnClose
        maskStyle={{
          background: 'rgba(0,0,0,0.8)',
        }}
        visible={this.state.visible}
        onClose={this.onClose}
        className="introduce--modal--wrapper"
        closeIcon={
          <div className="close-modal">
            <i className="flaticon-cancel" /> ปิดหน้าต่างนี้
          </div>
        }
      >
        <Img fluid={this.imageFluid} />
        <div className="content--wrapper">
          {this.nextButton}
          <div className="step">{this.step}</div>
        </div>
      </Dialog>
      // </div>
    )
  }
}
