import React from 'react'
import { Badge } from 'drivehub-rc'
import { Card } from '../../../components/card'
import styled from 'styled-components'
import './style.scss'

export const CarCard = ({ className, car, prefix, dateTime, url }) => {
  const badgeSource = () => {
    if (car.source === 'original') {
      return <Badge type="local" size="small" />
    } else {
      return <Badge type="inter" size="small" />
    }
  }

  const badgeCash = () => {
    if (car.source === 'original') {
      return <Badge type="local" size="medium" payment />
    } else {
      return <Badge type="inter" size="medium" payment />
    }
  }

  const badgeValueProp = () => {
    if (car.source === 'original') {
      return <div className="badge-value-prop--local">ได้รถคันที่เลือก</div>
    } else {
      return <div className="badge-value-prop--inter">ได้รถเทียบเท่า</div>
    }
  }

  const getPrice = car => {
    if (car.slash_price_per_day === '') {
      return (
        <div className="normal-price">
          {Number(car.price_per_day).toLocaleString()}
        </div>
      )
    } else {
      return (
        <div className="discount-box">
          <div className="discounted-price">
            {Number(car.slash_price_per_day).toLocaleString()}
          </div>
          <div className="normal-price normal-price--small ">
            <SlashBox car={car}>
              {Number(car.price_per_day).toLocaleString()}
            </SlashBox>
          </div>
        </div>
      )
    }
  }

  return (
    <Card className={className}>
      <a href={url}>
        <div
          className="card-car__img"
          style={{
            backgroundImage: `url('${car.image_1}')`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          {badgeSource()}
        </div>
        <div className="card-car__title">
          <h4>{car.title[prefix]}</h4>
          <span className="sub-title-label">รถว่างเวลา</span>
          <span className="sub-title-datetime">{dateTime}</span>
          <div className="underline" />
        </div>
        <div className="card-car__detail">
          <div className="detail-badge">
            {badgeCash()}
            {badgeValueProp()}
          </div>
          <div className="detail-price">
            {getPrice(car)}
            <div className="unit-price">บาท/วัน</div>
          </div>
        </div>
      </a>
    </Card>
  )
}

const SlashBox = styled.div`
  /* pseudo selectors work as well */
  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: ${props => (props.car.price_per_day.length > 3 ? '0px' : '-1px')};
    border-bottom: 2px solid #d0021b;
    border-radius: 5px;
    transform-origin: 0% 0%;
    width: ${props =>
      props.car.price_per_day.length > 3
        ? 'calc(100% + 2px)'
        : 'calc(100% + 4px)'};
    transform: ${props =>
      props.car.price_per_day.length > 3 ? 'rotate(-19deg)' : 'rotate(-24deg)'};
  }
`
