import './style.scss'
import React, { PureComponent } from 'react'
import ServiceDrivehub from './service-drivehub'
import ServiceAlliance from './service-alliance'

export default class SectionServices extends PureComponent {
  render() {
    return (
      <div className="section__services">
        <div className="container container--services">
          <ServiceDrivehub />
          <ServiceAlliance />
        </div>
      </div>
    )
  }
}
