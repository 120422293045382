import React, { PureComponent } from 'react'
import { withTranslation } from '../../../components/locales'
import LazyLoad from 'react-lazyload'
import axios from 'axios'
import {
  BIGBRAND_API,
  API_LEGACY_LINK,
  WEB_SEARCH_LINK,
} from '../../../../config'
import './style.scss'

class BookingRetrieve extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      bookingID: '',
      lastName: '',
      isError: false,
    }
  }

  callAPISearchBooking = (isBigbrand, bookingID, lastName) => {
    const baseAPI = isBigbrand ? BIGBRAND_API : API_LEGACY_LINK
    const source = isBigbrand ? 'inter' : 'local'

    axios
      .get(`${baseAPI}/bookings/${bookingID}?last_name=${lastName}`)
      .then(() => {
        window.location = `${WEB_SEARCH_LINK}/booking/${bookingID}?source=${source}&last_name=${lastName}`
      })
      .catch(_ => {
        this.setState({ isError: true })
      })
  }

  searchingBooking = event => {
    event.preventDefault()
    const { bookingID, lastName } = this.state
    if (bookingID === '' || lastName === '') return
    const numberBookingID = Number(bookingID)
    // FIXME: check source by other solution
    if (numberBookingID < 100000) {
      // Inter Booking
      this.callAPISearchBooking(true, numberBookingID, lastName)
    } else {
      // Local booking
      this.callAPISearchBooking(false, numberBookingID, lastName)
    }
  }

  render() {
    const { t } = this.props
    const { bookingID, lastName, isError } = this.state
    return (
      <LazyLoad>
        <div className="section__booking-retrieve">
          <div className="container container--booking-retrieve">
            <h3>{t('booking_retrieve.header')}</h3>
            <form>
              <div className="row">
                <div className="col-12 col-md-5">
                  <input
                    className={isError ? 'red' : ''}
                    text="text"
                    name="bookingID"
                    value={bookingID}
                    onChange={event =>
                      this.setState({
                        bookingID: event.target.value,
                      })
                    }
                    placeholder={t('booking_retrieve.booking_id')}
                  />
                </div>
                <div className="col-12 col-md-5">
                  <input
                    className={isError ? 'red' : ''}
                    text="text"
                    name="lastName"
                    value={lastName}
                    onChange={event =>
                      this.setState({
                        lastName: event.target.value,
                      })
                    }
                    placeholder={t('booking_retrieve.last_name')}
                  />
                </div>
                {isError && (
                  <div className="col-12 col-md-5 d-md-none">
                    <small className="error mobile">
                      {t('booking_retrieve.booking_not_found')}
                    </small>
                  </div>
                )}
                <div className="col-12 col-md-2">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                    onClick={this.searchingBooking}
                  >
                    {t('booking_retrieve.search')}
                  </button>
                </div>
              </div>
              {isError && (
                <small className="error desktop d-none d-md-block">
                  {t('booking_retrieve.booking_not_found')}
                </small>
              )}
            </form>
          </div>
        </div>
      </LazyLoad>
    )
  }
}

export default withTranslation('homepage')(BookingRetrieve)
