import React, { PureComponent } from 'react'
import './style.scss'

class Dropdown extends PureComponent {
  handleClick = () => {
    const { isMobile, isOpen } = this.props
    if (isOpen && !isMobile) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
  }

  handleOutsideClick = e => {
    if (
      this.dropDownRef.current &&
      this.dropDownRef.current.contains(e.target)
    ) {
      return
    }
    this.props
      .toggle()
      .then(() =>
        document.removeEventListener('click', this.handleOutsideClick, false),
      )
  }

  render() {
    const {
      id,
      list,
      isMobile,
      isOpen,
      toggle,
      onChange,
      placeholder,
      value,
    } = this.props

    return (
      <div
        className={`dropdown-container ${
          isMobile ? 'dropdown-container--mobile' : ''
        }`}
        ref={ref => {
          this.dropDownRef = ref
        }}
      >
        {isMobile ? (
          <select
            onChange={e =>
              onChange(e.target.options[e.target.selectedIndex].text)
            }
            value={this.props.value}
            id={id}
            onClick={this.handleClick}
          >
            {this.props.list.map(item => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        ) : (
          <div
            className="dropdown-box"
            onClick={() => {
              toggle().then(() => this.handleClick())
            }}
          >
            {placeholder}
            <i
              className={isOpen ? 'flaticon-arrow-up' : 'flaticon-arrow-down'}
            />
            <div
              className={`dropdown-content ${
                isOpen ? 'dropdown-content--active' : ''
              }`}
            >
              {list.map(item => {
                return (
                  <div
                    className={`dropdown-item ${
                      item.value === value ? 'dropdown-item--active' : ''
                    }`}
                    key={item.value}
                    onClick={e => onChange(e.currentTarget.innerText)}
                  >
                    {item.label}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Dropdown
