import React, { PureComponent } from 'react'
import ReactGA from 'react-ga'
import moment from 'moment-timezone'
import axios from 'axios'
import { MARKETING_API } from '../../../config'
import { setCookie, readCookie } from '../../utils'
import './style.scss'

class PromotionModal extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isShowPromo: false,
      isTextCopy: false,
      voucherCode: '',
    }
  }

  componentDidMount() {
    if (this.isFirstTimeVisit()) return
    if (this.isComeBackUser() === false) return

    this.getVoucherCode().then(code => {
      if (code) {
        setTimeout(() => {
          this.setState({ isShowPromo: true })
          setCookie('_spu', true, 365, '/')
          // window.fbq('track', 'Returning')

          // ReactGA.event({
          //   category: 'returning_user',
          //   action: 'initial',
          //   label: 'initial',
          // })
        }, 5000)
      }
    })
  }

  isFirstTimeVisit = () => {
    if (readCookie('__duid') === null) {
      return true
    } else {
      return readCookie('_init') === null ? true : false
    }
  }

  getVoucherCode = () => {
    const duid = readCookie('__duid')
    return axios
      .get(`${MARKETING_API}/voucher/get`, {
        params: {
          duid: duid,
        },
      })
      .then(result => {
        const code = result['data']['code']
        this.setState({ voucherCode: code })
        return code
      })
      .catch(e => {
        console.log(e)
      })
  }

  isComeBackUser = () => {
    const lastVisit = readCookie('_lv')
    const isSeenPromo = readCookie('_spu')
    const bookRef = readCookie('_cv')

    const diffDateTime = moment(moment(), 'YYYY-MM-DD HH:mm').diff(
      moment(window.atob(lastVisit)),
    )
    // const diffDays = moment.duration(diffDateTime).days()
    const diffDays = moment.duration(diffDateTime).minutes()

    if (bookRef) return false
    if (isSeenPromo) return true

    return diffDays >= 1 ? true : false
  }

  modalToggle = () => {
    this.setState({ isShowPromo: !this.state.isShowPromo })
  }

  copyButtonToggle = async () => {
    await this.setState({ isTextCopy: true })
  }

  copyCodeText = () => {
    const promoCodeField = document.getElementById('promo-code-text')
    const textArea = document.createElement('textarea')
    textArea.value = promoCodeField.textContent
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('Copy')
    textArea.remove()

    this.copyButtonToggle().then(() => {
      setTimeout(() => this.setState({ isTextCopy: false }), 3500)
    })
  }
  render() {
    const { isShowPromo, isTextCopy, voucherCode } = this.state

    return (
      <div className={`promo-modal-container ${isShowPromo ? 'activate' : ''}`}>
        <div className="promo-box">
          <div className="promo-box__content">
            <div className="promo-img">
              <img
                className="badge-type"
                src="/assets/promotions/promo-car-logo.png"
                alt="badge-type"
              />
              <img
                className="car-img"
                src="/assets/promotions/promo-car.png"
                alt="car-img"
              />
            </div>
            <div className="promo-description">
              <div className="description-header">
                <p>พิเศษเฉพาะคุณเท่านั้น</p>
                <p className="font-weight-bold">
                  จองวันนี้{' '}
                  <span className="header-discount-text">ลดทันที 250 บาท</span>
                </p>
              </div>
              <div className="description-button" onClick={this.copyCodeText}>
                <div id="promo-code-text">{voucherCode}</div>
                <div id="promo-copy-btn">คัดลอก</div>
                <div
                  className={`success-text-box ${isTextCopy ? 'active' : ''}`}
                >
                  <i className="flaticon-checked-circle" />
                  สำเร็จ!
                </div>
              </div>
            </div>
          </div>
          <div className="promo-box__notice">
            <p>
              *โค้ดส่วนลดนี้ใช้ได้เฉพาะจองบริษัทรถเช่าท้องถิ่น (LOCAL) และจอง 2
              วันขึ้นไป*
            </p>
          </div>
          <div className="promo-close-icon">
            <i className="flaticon-cancel" onClick={this.modalToggle} />
          </div>
        </div>
      </div>
    )
  }
}

export default PromotionModal
