import React, { Component } from 'react'
import empty_car_icon from '../../../static/assets/empty_car_icon.svg'
import './style.scss'

export default class EmptyBox extends Component {
  renderOtherPickupLocation = () => {
    const { displayPickupLocationsId, pickupLocations, onClickPickup } = this.props
    const otherPickupLo = pickupLocations
      .filter(el => !displayPickupLocationsId.includes(el.id))
      .slice(0, 3)

    return (
      <div className="empty-box__other">
        {otherPickupLo.map(el => (
          <div key={el.id} className="pickup-location-item">
            <div className="pickup-box" onClick={() => onClickPickup(el)}>
              <i className="flaticon-car-location-icon" />
              <span>รับที่{el.name.th}</span>
            </div>
            {/* <p>พบรถว่างทั้งหมด <b>125</b> คัน</p> */}
          </div>
        ))}
      </div>
    )
  }

  render() {
    const { displayPickupLocationsId, pickupLoSelected, isHomePage } = this.props

    return (
      <div className="empty-box">
        <img src={empty_car_icon} alt="Empty Car" />
        <strong>ไม่พบรถว่าง</strong>
        {isHomePage ? (
          ''
        ) : (
          <React.Fragment>
            <div className="empty-box__text-box">
              <p>แต่ท่านสามารถเลือกรับรถว่าง</p>
              <p>
                ที่จุดรับรถเช่าใกล้เคียงกับ
                <span>{pickupLoSelected.name.th}</span>
              </p>
              <p>ดังต่อไปนี้</p>
            </div>
            {displayPickupLocationsId.includes(pickupLoSelected.id) &&
              this.renderOtherPickupLocation()}
          </React.Fragment>
        )}
      </div>
    )
  }
}
