import React, { PureComponent } from 'react'
import { withTranslation } from '../../../components/locales'
import moment from 'moment'
import queryString from 'querystring'
import axios from 'axios'
import LazyLoad from 'react-lazyload'
import Dropdown from '../../../components/dropdown'
import DhSlick from '../../../components/slick'
import EmptyBox from '../../../components/empty-box'

import { setupDateTime } from '../../../utils/index'
import { LoadingWheel } from '../../../components/loading'
import { DhDots } from '../../../components/slick/custom-slick'
import { CarCard } from './card'
import {
  API_GET_LOCATIONS,
  API_GET_CARS,
  WEB_SEARCH_LINK,
} from '../../../../config'
import './style.scss'

const DEFAULT_CITY = [2, 1, 3, 7]
class SectionCarAvailable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cities: [],
      cars: [],
      isLoading: true,
      isOpenDropdown: false,
      cityID: this.props.cityID,
      placeholder: '',
      totalCars: 0,
      pickupDateTime: setupDateTime(true).format('YYYY-MM-DD HH:mm'),
      returnDateTime: setupDateTime(false).format('YYYY-MM-DD HH:mm'),
    }
  }

  componentDidMount() {
    this.fetchCities().then(() => {
      this.fetchCars()
    })
  }

  fetchCities = async () => {
    const { prefix } = this.props
    axios
      .get(API_GET_LOCATIONS)
      .then(({ data }) => {
        data.sort((a, b) => a.name[prefix].localeCompare(b.name[prefix]))
        const defaultCity = data.filter(el => this.props.cityID === el.id)
        this.setState({
          cities: data,
          cityID: defaultCity[0].id,
          placeholder: defaultCity[0].name[prefix],
        })
      })
      .catch(err => console.log(err))
  }

  fetchCars = async () => {
    for (let i = 0; i < DEFAULT_CITY.length; i++) {
      const result = await axios
        .get(this.urlGetCars(DEFAULT_CITY[i]))
        .then(({ data }) => {
          const countSmallEco = data.cars.filter(
            car => car.category.th === 'eco' || car.category.th === 'small',
          ).length
          const city = this.state.cities.filter(
            city => city.id === DEFAULT_CITY[i],
          )
          if (countSmallEco >= 4) {
            this.setState({
              cars: data.cars,
              isLoading: false,
              cityID: DEFAULT_CITY[i],
              placeholder: city[0].name[this.props.prefix],
              totalCars: data.count,
            })
            return true
          } else if (DEFAULT_CITY.length - 1 === i) {
            this.setState({
              cars: data.cars,
              isLoading: false,
              cityID: DEFAULT_CITY[i],
              placeholder: city[0].name[this.props.prefix],
              totalCars: data.count,
            })
            return true
          } else {
            return false
          }
        })
        .catch(err => console.log(err))
      if (result) {
        break
      }
    }
  }

  urlGetCars = cityID => {
    const { pickupDateTime, returnDateTime } = this.state
    const apiQuery = {
      city_id: cityID,
      limit: 10,
      offset: 0,
      order_by: 'price',
      pickup_datetime: moment(pickupDateTime, 'YYYY-MM-DD HH:mm').format(),
      return_datetime: moment(returnDateTime, 'YYYY-MM-DD HH:mm').format(),
    }

    const parsedQuery = queryString.stringify(apiQuery)
    return `${API_GET_CARS}?${parsedQuery}`
  }

  getCarPickupDateTime = () => {
    moment.locale('th')
    const momentPickup = moment(this.state.pickupDateTime, 'YYYY-MM-DD HH:mm')
    return momentPickup.format('HH:mm น. (DD MMMYY)')
  }

  getUrlToCarDetail = car => {
    const { pickupDateTime, returnDateTime } = this.state

    const source = car.source === 'original' ? 'local' : 'inter'

    const parsedQuery = queryString.stringify({
      location_id: car.location.id,
      open_search: true,
      pickup_datetime: pickupDateTime,
      return_datetime: returnDateTime,
      source,
    })
    return `${WEB_SEARCH_LINK}/car/${car.id}?${parsedQuery}`
  }

  toggleDropdown = async () => {
    this.setState({ isOpenDropdown: !this.state.isOpenDropdown })
  }

  getSearchResultLink = () => {
    const { pickupDateTime, returnDateTime, cityID, cities } = this.state
    const city = cities.find(c => c.id === cityID)

    if (!city) {
      return ''
    }

    const locationId = city.locations[0].id

    const parsedQuery = queryString.stringify({
      booking_begin: pickupDateTime,
      booking_end: returnDateTime,
      location_id: locationId,
      open_search: true,
    })

    return `${WEB_SEARCH_LINK}/?${parsedQuery}`
  }

  formatCitiesList = cities => {
    const { prefix } = this.props
    const formatList = []
    cities.forEach(city => {
      let formatCity = {
        label: city.name[prefix],
        value: city.id,
      }
      formatList.push(formatCity)
    })
    return formatList
  }

  onChange = cityName => {
    const { cities } = this.state
    const city = cities.filter(
      city => city.name[this.props.prefix] === cityName,
    )

    this.setState(
      {
        cityID: city[0].id,
        placeholder: city[0].name[this.props.prefix],
        isLoading: true,
      },
      () => {
        this.fetchCars()
      },
    )
  }

  slickSettings = totalCars => {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      appendDots: dots => (
        <DhDots modifyClassName="cars-footer">
          <div className="total-car">พบรถว่างทั้งหมด {totalCars} คัน</div>
          <ul>{dots}</ul>
          <a href={this.getSearchResultLink()} className="btn-see-all">
            ดูทั้งหมด
            <i className="flaticon-next" />
          </a>
        </DhDots>
      ),
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    }
  }

  render() {
    const { t, isMobile, prefix } = this.props
    const {
      cities,
      isOpenDropdown,
      placeholder,
      cars,
      isLoading,
      totalCars,
    } = this.state

    return (
      <div className="section__car-available">
        <div className="container container--car-available">
          <div className="car-available-header">
            <h2>{t('car_available.header')}</h2>
          </div>
          <div className="car-available-city">
            <p className="city-label">{t('car_available.city_label')}</p>
            <Dropdown
              id={'car-available-city'}
              isMobile={isMobile}
              list={this.formatCitiesList(cities)}
              isOpen={isOpenDropdown}
              toggle={this.toggleDropdown}
              value={this.state.cityID}
              onChange={this.onChange}
              placeholder={
                placeholder === ''
                  ? t('car_available.placeholder')
                  : placeholder
              }
            />
          </div>
          <LazyLoad>
            <div
              className={`car-available-cars ${
                isMobile ? 'car-available-cars--mobile' : ''
              }`}
            >
              {isLoading ? (
                <div
                  style={{
                    minHeight: 350,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LoadingWheel height={40} width={40} />
                </div>
              ) : cars.length === 0 ? (
                <EmptyBox isHomePage />
              ) : isMobile ? (
                <React.Fragment>
                  <div className="cars-container">
                    {cars.map(car => {
                      return (
                        <CarCard
                          className="card-car"
                          car={car}
                          prefix={prefix}
                          dateTime={this.getCarPickupDateTime()}
                          url={this.getUrlToCarDetail(car)}
                          key={car.id}
                        />
                      )
                    })}
                    <div className="cars-container card-car-extar"></div>
                  </div>
                  <div className="cars-footer">
                    <div className="total-car">
                      พบรถว่างทั้งหมด {totalCars} คัน
                    </div>
                    <a
                      href={this.getSearchResultLink()}
                      className="btn-see-all"
                    >
                      ดูทั้งหมด
                      <i className="flaticon-next" />
                    </a>
                  </div>
                </React.Fragment>
              ) : (
                <DhSlick
                  settings={this.slickSettings(totalCars)}
                  isLoading={isLoading}
                >
                  {cars.map(car => {
                    return (
                      <CarCard
                        className="card-car"
                        car={car}
                        prefix={prefix}
                        dateTime={this.getCarPickupDateTime()}
                        url={this.getUrlToCarDetail(car)}
                        key={car.id}
                      />
                    )
                  })}
                </DhSlick>
              )}
            </div>
          </LazyLoad>
        </div>
      </div>
    )
  }
}

export default withTranslation('homepage')(SectionCarAvailable)
